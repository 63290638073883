import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import 'intersection-observer';
import getConfig from 'next/config';
import Script from 'next/script';
import 'react-dates/initialize';
import '../index.scss';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(
                        distDir,
                        'app:///_next'
                    );
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

function MyApp({ Component, pageProps, err }) {
    const showMarkerIo = pageProps?.componentProps?.showMarkerIo;

    return (
        <>
            {showMarkerIo && (
                <>
                    <Script id="marker-conf">
                        {`window.markerConfig = {
            project: '64dcb3b7c5420a6761e26390',
            source: 'snippet'
          };`}
                    </Script>
                    <Script
                        id="marker-script"
                        dangerouslySetInnerHTML={{
                            __html: `!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);`,
                        }}
                    />
                </>
            )}
            <Component {...pageProps} err={err} />
        </>
    );
}

export default MyApp;
